const FieldNames = {
  funeralHomeName: "funeralHomeName",
  funeralHomeContact: "funeralHomeContact",
  funeralHomeStreet: "funeralHomeStreet",
  funeralHomeCity: "funeralHomeCity",
  funeralHomeState: "funeralHomeState",
  funeralHomeZip: "funeralHomeZip",
  funeralHomeTelephone: "funeralHomeTelephone",
  funeralHomeEmail: "funeralHomeEmail",
  funeralHomeShipMerchandiseTo: "funeralHomeShipMerchandiseTo",
  funeralHomeShipMerchandiseToFuneralDirector: "FuneralDirector",
  funeralHomeShipMerchandiseToFamilyOfDeceased: "FamilyOfDeceased",

  deceasedName: "deceasedName",
  deceasedDateOfBirth: "deceasedDateOfBirth",
  deceasedDateOfDeath: "deceasedDateOfDeath",
  deceasedIntermentInformation: "deceasedIntermentInformation",

  familyContactName: "familyContactName",
  familyContactStreet: "familyContactStreet",
  familyContactCity: "familyContactCity",
  familyContactState: "familyContactState",
  familyContactZip: "familyContactZip",
  familyContactTelephone: "familyContactTelephone",
  familyContactEmail: "familyContactEmail",

  sympathyAcknowledgementsQuantity: "sympathyAcknowledgementsQuantity",
  sympathyAcknowledgementsColors: "sympathyAcknowledgementsColors",
  sympathyAcknowledgementsSentiment: "sympathyAcknowledgementsSentiment",
  sympathyAcknowledgementsFont: "sympathyAcknowledgementsFont",
  sympathyAcknowledgementsFontInk: "sympathyAcknowledgementsFontInk",
  sympathyAcknowledgementsMotif: "sympathyAcknowledgementsMotif",
  sympathyAcknowledgementsMotifInk: "sympathyAcknowledgementsMotifInk",
  sympathyAcknowledgementsPaper: "sympathyAcknowledgementsPaper",
  sympathyAcknowledgementsReturnAddressStreet:
    "sympathyAcknowledgementsReturnAddressStreet",
  sympathyAcknowledgementsReturnAddressCity:
    "sympathyAcknowledgementsReturnAddressCity",
  sympathyAcknowledgementsReturnAddressState:
    "sympathyAcknowledgementsReturnAddressState",
  sympathyAcknowledgementsReturnAddressZip:
    "sympathyAcknowledgementsReturnAddressZip",

  remembrancePotraitStyle: "remembrancePotraitStyle",
  remembrancePotraitSize: "remembrancePotraitSize",
  remembrancePotraitPhoto: "remembrancePotraitPhoto",
  remembrancePotraitSpecialInsctructions:
    "remembrancePotraitSpecialInsctructions",

  memorialPrayerCardQuantity: "memorialPrayerCardQuantity",
  memorialPrayerCardStyle: "memorialPrayerCardStyle",
  memorialPrayerCardVerse: "memorialPrayerCardVerse",
  memorialPrayerCardInk: "memorialPrayerCardInk",
  memorialPrayerCardPaper: "memorialPrayerCardPaper",
  memorialPrayerCardCustomVerse: "memorialPrayerCardCustomVerse",
  memorialPrayerCardCustomVerseFile: "memorialPrayerCardCustomVerseFile",

  memorialBookmarkObituaryQuantity: "memorialBookmarkObituaryQuantity",
  memorialBookmarkObituaryPaper: "memorialBookmarkObituaryPaper",
  memorialBookmarkObituaryTasselColor: "memorialBookmarkObituaryTasselColor",
  memorialBookmarkObituaryFile: "memorialBookmarkObituaryFile",

  donationCardsWithEnvelopesQuantity: "donationCardsWithEnvelopesQuantity",
  donationCardsWithEnvelopesDonationInformation:
    "donationCardsWithEnvelopesDonationInformation",
  donationCardsWithEnvelopesRecepientAddressStreet:
    "donationCardsWithEnvelopesRecepientAddressStreet",
  donationCardsWithEnvelopesRecepientAddressCity:
    "donationCardsWithEnvelopesRecepientAddressCity",
  donationCardsWithEnvelopesRecepientAddressState:
    "donationCardsWithEnvelopesRecepientAddressState",
  donationCardsWithEnvelopesRecepientAddressZip:
    "donationCardsWithEnvelopesRecepientAddressZip",

  funeralProgramsQuantity: "funeralProgramsQuantity",
  funeralProgramsPaper: "funeralProgramsPaper",
  funeralProgramsUploadPhotoAndCopy: "funeralProgramsUploadPhotoAndCopy",
  funeralProgramsSpecialInstructions: "funeralProgramsSpecialInstructions",
};

export default FieldNames;
