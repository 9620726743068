import Axios from "axios";
import React, { Component } from "react";

import { withRouter } from "react-router-dom";

class Success extends Component {
  state = {};
  componentDidMount = async () => {
    const data = JSON.parse(localStorage.getItem("data"));
    const tok = data.token;
    const response = await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/confirm-payment`,
      { token: tok }
    );
    console.log(response.data);
  };
  render() {
    return (
      <>
        <section className="p-5 text-2xl text-center font-semibold">
          <h3>
            Thank you for your order
            <br />
            You will recieve a confirmation email shortly
          </h3>
        </section>
      </>
    );
  }
}

export default withRouter(Success);
