import React from "react";

import { toast, ToastContainer as TC, Zoom } from "react-toastify";

const ToastContainer = () => {
  return (
    <TC
      position="top-right"
      autoClose={1000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover={true}
      pauseOnFocusLoss={false}
      closeButton={false}
      transition={Zoom}
    />
  );
};

export { toast, ToastContainer };
