import React, { Component } from "react";
import { Formik } from "formik";
import { Fields } from "./FormFields";
import Names from "./FieldNames";
import "./app.css";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "../Toast";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

class Form extends Component {
  state = {
    initialValues: Fields,
    isSubmitting: false,
    errors: [],
    customerID: "",
    isLoading: false,
    isLoaded: false,
  };

  validate = (values) => {
    const messages = [];
    if (!values) {
      messages.push("Fill all fields");
    }

    if (values.funeralHomeName == "") {
      messages.push("Enter funeral home name");
    }
    if (values.funeralHomeContact == "") {
      messages.push("Enter funeral home contact");
    }

    if (values.funeralHomeStreet == "") {
      messages.push("Enter funeral home  street");
    }
    if (values.funeralHomeCity == "") {
      messages.push("Enter funeral home city");
    }
    if (values.funeralHomeZip == "") {
      messages.push("Enter funeral home zip");
    }
    if (values.funeralHomeTelephone == "") {
      messages.push("Enter funeral home telephone");
    }
    if (values.funeralHomeEmail == "") {
      messages.push("Enter funeral home email");
    }
    if (!values.funeralHomeShipMerchandiseTo) {
      messages.push("Chose where to ship mechandise ");
    }
    ///DeCeseased information
    if (values.deceasedName == "") {
      messages.push("Enter deceased full name");
    }
    if (values.deceasedDateOfBirth == "") {
      messages.push("Enter deceased date of birth");
    }
    if (values.deceasedDateOfDeath == "") {
      messages.push("Enter deceased date of death");
    }
    if (values.deceasedIntermentInformation == "") {
      messages.push("Enter deceased interment information");
    }
    //Family Info
    if (
      values.familyContactName == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact name");
    }
    if (
      values.familyContactStreet == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact street");
    }
    if (
      values.familyContactCity == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact city");
    }
    if (
      values.familyContactState == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact's state");
    }
    if (
      values.familyContactZip == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact zip");
    }
    if (
      values.familyContactTelephone == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact telephone");
    }
    if (
      values.familyContactEmail == "" &&
      values.funeralHomeShipMerchandiseTo === "FamilyOfDeceased"
    ) {
      messages.push("Enter family contact email");
    }
    //Sympathy Acknoledment
    if (values.sympathyAcknowledgementsQuantity == "") {
      messages.push("Enter acknowledgement cards quantity");
    }
    if (
      values.sympathyAcknowledgementsSentiment == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card sentiment");
    }
    if (
      values.sympathyAcknowledgementsFont == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card font");
    }
    if (
      values.sympathyAcknowledgementsFontInk == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card text font");
    }
    if (
      values.sympathyAcknowledgementsMotif == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card motif");
    }
    if (
      values.sympathyAcknowledgementsMotifInk == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card motif ink");
    }
    if (
      values.sympathyAcknowledgementsPaper == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement card paper");
    }
    if (
      values.sympathyAcknowledgementsReturnAddressStreet == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement cards return address street");
    }
    if (
      values.sympathyAcknowledgementsReturnAddressCity == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement cards return address city");
    }
    if (
      values.sympathyAcknowledgementsReturnAddressState == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement cards return address state");
    }
    if (
      values.sympathyAcknowledgementsReturnAddressZip == "" &&
      values.sympathyAcknowledgementsQuantity !== "0"
    ) {
      messages.push("Enter acknowledgement cards return address zip");
    }

    // MomoRial Prayer
    if (values.memorialPrayerCardQuantity == "") {
      messages.push("Enter prayer card quantity");
    }
    if (
      values.memorialPrayerCardStyle == "" &&
      values.memorialPrayerCardQuantity !== "0"
    ) {
      messages.push("Enter card style");
    }
    if (
      values.memorialPrayerCardVerse == "" &&
      values.memorialPrayerCardQuantity !== "0"
    ) {
      messages.push("Enter card verse");
    }
    if (
      values.memorialPrayerCardVerse == "customVerse" &&
      values.memorialPrayerCardCustomVerseFile == ""
    ) {
      messages.push("Upload prayer card custom verse");
    }

    if (
      values.memorialPrayerCardInk == "" &&
      values.memorialPrayerCardQuantity !== "0"
    ) {
      messages.push("Enter memorial prayer card ink");
    }
    if (
      values.memorialPrayerCardPaper == "" &&
      values.memorialPrayerCardQuantity !== "0"
    ) {
      messages.push("Enter memorial prayer card paper");
    }
    ///Orbituary
    if (values.memorialBookmarkObituaryQuantity == "") {
      messages.push("Enter obituary bookmark quantity");
    }
    if (
      values.memorialBookmarkObituaryPaper == "" &&
      values.memorialBookmarkObituaryQuantity !== "0"
    ) {
      messages.push("Enter obituary bookmark paper");
    }
    if (
      values.memorialBookmarkObituaryTasselColor == "" &&
      values.memorialBookmarkObituaryQuantity !== "0"
    ) {
      messages.push("Enter obituary bookmark tassel color");
    }
    if (
      values.memorialBookmarkObituaryFile == "" &&
      values.memorialBookmarkObituaryQuantity !== "0"
    ) {
      messages.push("Upload obituary bookmark Obituary file");
    }
    //// Doantion Cards
    if (values.donationCardsWithEnvelopesQuantity == "") {
      messages.push("Enter donation Card Quantity");
    }

    if (
      values.donationCardsWithEnvelopesDonationInformation == "" &&
      values.donationCardsWithEnvelopesQuantity !== "0"
    ) {
      messages.push("Enter donation information");
    }
    if (
      values.donationCardsWithEnvelopesRecepientAddressStreet == "" &&
      values.donationCardsWithEnvelopesQuantity !== "0"
    ) {
      messages.push("Enter doantion card recepient street");
    }
    if (
      values.donationCardsWithEnvelopesRecepientAddressCity == "" &&
      values.donationCardsWithEnvelopesQuantity !== "0"
    ) {
      messages.push("Enter doantion card recepient city");
    }
    if (
      values.donationCardsWithEnvelopesRecepientAddressState == "" &&
      values.donationCardsWithEnvelopesQuantity !== "0"
    ) {
      messages.push("Enter doantion card recepient state");
    }
    if (
      values.donationCardsWithEnvelopesRecepientAddressZip == "" &&
      values.donationCardsWithEnvelopesQuantity !== "0"
    ) {
      messages.push("Enter doantion card recepient zip");
    }
    ///Funeral Program
    if (values.funeralProgramsQuantity == "") {
      messages.push("Enter funeral programs quantity");
    }
    if (
      values.funeralProgramsPaper == "" &&
      values.funeralProgramsQuantity !== "0"
    ) {
      messages.push("Enter funeral programs paper");
    }
    if (
      values.funeralProgramsUploadPhotoAndCopy == "" &&
      values.funeralProgramsQuantity !== "0"
    ) {
      messages.push("Upload funeral programs photo");
    }
    // Second layer for Checking Email and make sure at least one service is Selected

    let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexp.test(values.funeralHomeEmail)) {
      messages.push("Funeral home email is invalid");
    }
    //////////////
    return messages;
  };
  fetchInfo = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const obj = { id: this.state.customerID };
    try {
      let resp = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/fetchinfo`,
        obj
      );
      resp = resp.data;
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          funeralHomeName: resp.name,
          funeralHomeStreet: resp.street,
          funeralHomeCity: resp.city,
          funeralHomeState: resp.state,
          funeralHomeZip: resp.zip,
          funeralHomeTelephone: resp.contact,
        },
        isLoading: false,
        isLoaded: true,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };
  handleSubmit = async (values) => {
    const isValidate = this.validate(values);
     if (isValidate.length === 0) {
    
      this.setState({ isSubmitting: true, errors: [] });
      const data = new FormData();
      Object.keys(values).forEach((key) => {
        if (typeof(values[key])==="object"){
          let imgObj = values[key];
          for(let i = 0;i<imgObj.length;i++){
            data.append(key,imgObj[i])
          }
        }
        else
        {data.append(key, values[key]);}
      });
      try {
        const resp = await Axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/upload`,
          data
        );

        const respData = resp.data.data;
        localStorage.setItem("data", JSON.stringify(respData));
        this.setState({ isSubmitting: false });
        Swal.fire({
          position: "center",
          icon: "success",
          padding: "3.2rem",
          title: "Thank you for your order!",
          text: "You will receive a confirmation email shortly",
          showConfirmButton: false,
        });
      } catch (err) {
        toast.error(err?.message);
        this.setState({ isSubmitting: false });
      }
    } else {
      this.setState({ errors: isValidate });
    }
  };

  render() {
    const { initialValues, errors } = this.state;
    return (
      <>
        <Helmet>
          <title>Order Form</title>
        </Helmet>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values }) => (
            <div className="container mx-auto">
              {/* ///////////////////////////////////////////////////////<!-- BETA tag -->//////////////////////////////////// */}

              {/* <!-- BETA tag --> */}

              <div className="d-flex justify-content-between">
                <span class="mt-3 mb-8">
               
                    <a
                      className="text-black corsstownlink "
                      href="https://www.crosstown.press/home"
                    >
                      <svg
                        width="1.2em"
                        height="1.2em"
                        viewBox="0 0 16 16"
                        className="bi bi-chevron-left"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </a>
               
                </span>
                <span className="mt-2 mb-8">
                  <small className="text-gray-700 text-sm">Beta</small>
                </span>
              </div>

              <h1 className="text-5xl mt-5 text-center font-semibold">
                Honoring A Life
              </h1>
              <h2 className="text-3xl text-center mt-4">Order Form</h2>

              <>
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  noValidate
                  id="the-form"
                  className="w-full mt-12 mb-12"
                >
                  {/* <!-- Priliminary information --> */}
                  <div>
                    {/* <!-- funeral home --> */}
                    <div id="funeral-home">
                      <div className="input-group flex flex-wrap">
                        {/*////////////////////// customerID       /////////////// */}
                        <div className="w-full md:w-1/3 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor="customerId"
                          >
                            Customer ID
                          </label>

                          <div id="customerId" className="cutomer-class">
                            <input
                              type="text"
                              className="customer-input appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id={Names.funeralHomeNname}
                              value={this.state.customerID}
                              onChange={(event) => {
                                this.setState({
                                  customerID: event.target.value,
                                });
                              }}
                            />

                            <div className="">
                              {this.state.isLoading ? (
                                <div
                                  style={{
                                    marginTop: "12px",
                                    marginRight: "37px",
                                    marginLeft: "51px",
                                    position: "relative",
                                    zIndex: 1000,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                    }}
                                    class="spinner-border "
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {this.state.isLoaded ? (
                                    <div
                                      style={{
                                        marginTop: "12px",
                                        marginRight: "37px",
                                        marginLeft: "51px",
                                        position: "relative",
                                        zIndex: 1000,
                                      }}
                                    >
                                      <svg
                                        width="2em"
                                        height="2em"
                                        viewBox="0 0 16 16"
                                        class="bi bi-check2"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                                        />
                                      </svg>
                                    </div>
                                  ) : (
                                    <span className="cutomer-btn appearance-none block w-full   pt-3 pl-3  leading-tight focus:outline-none focus:bg-white">
                                      <button
                                        className="cutomer-button text-sm font-medium  pb-1"
                                        type="button"
                                        onClick={this.fetchInfo}
                                      >
                                        Fetch Info
                                      </button>
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Name */}
                        <div className="w-full md:w-1/3 px-3 ">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeName}
                          >
                            Funeral Home
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeName}
                            value={values.funeralHomeName}
                            id={Names.funeralHomeNname}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                        {/* <!-- Contact --> */}
                        <div className="w-full md:w-1/3 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeContact}
                          >
                            Contact Name
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeContact}
                            id={Names.funeralHomeContact}
                            value={values.funeralHomeContact}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                        {/* <!-- Date --> */}

                        {/* <div className="w-full md:w-1/3 px-3">
                 
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.funeralHomeDate}
                        >
                          Date
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          name={Names.funeralHomeDate}
                          id={Names.funeralHomeDate}
                          value={values.funeralHomeDate}
                          onChange={handleChange}
                          type="text"
                        />
                      </div> */}
                      </div>

                      {/* <!-- street --> */}
                      <div className="flex">
                        <div className="w-full px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeStreet}
                          >
                            Street
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeStreet}
                            id={Names.funeralHomeStreet}
                            value={values.funeralHomeStreet}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </div>
                      {/* <!-- City, State, ZIP --> */}
                      <div className="flex flex-wrap">
                        {/* <!-- City --> */}
                        <div className="w-full md:w-1/3 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeCity}
                          >
                            City
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeCity}
                            id={Names.funeralHomeCity}
                            value={values.funeralHomeCity}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                        {/* <!-- State --> */}
                        <div className="w-full px-3 md:w-1/3 relative">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeState}
                          >
                            State
                          </label>
                          <select
                            name={Names.funeralHomeState}
                            id={Names.funeralHomeState}
                            value={values.funeralHomeState}
                            onChange={handleChange}
                            className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          >
                            <option value="">Please select</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="District Of Columbia">
                              District Of Columbia
                            </option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">
                              North Carolina
                            </option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">
                              South Carolina
                            </option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="Vermont">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        {/* <!-- ZIP --> */}
                        <div className="w-full md:w-1/3 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeZip}
                          >
                            Zip Code
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeZip}
                            id={Names.funeralHomeZip}
                            value={values.funeralHomeZip}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </div>
                      {/* <!-- Telephone, Email --> */}
                      <div className="flex flex-wrap">
                        {/* <!-- Telephone --> */}
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeTelephone}
                          >
                            Telephone
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeTelephone}
                            id={Names.funeralHomeTelephone}
                            value={values.funeralHomeTelephone}
                            onChange={handleChange}
                            type="tel"
                            placeholder=""
                          />
                        </div>
                        {/* <!-- Email --> */}
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.funeralHomeEmail}
                          >
                            Email
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.funeralHomeEmail}
                            id={Names.funeralHomeEmail}
                            value={values.funeralHomeEmail}
                            onChange={handleChange}
                            type="email"
                            placeholder=""
                          />
                        </div>
                      </div>
                      {/* <!-- Ship Merchandise to --> */}
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm font-bold  mb-2"
                            htmlFor={
                              Names.funeralHomeShipMerchandiseToFuneralDirector
                            }
                          >
                            SHIP MERCHANDISE TO
                          </label>
                          <div>
                            {/* <!-- Funeral Director --> */}
                            <input
                              type="radio"
                              id={
                                Names.funeralHomeShipMerchandiseToFuneralDirector
                              }
                              name={Names.funeralHomeShipMerchandiseTo}
                              value={
                                Names.funeralHomeShipMerchandiseToFuneralDirector
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="ml-2 text-sm"
                              htmlFor={
                                Names.funeralHomeShipMerchandiseToFuneralDirector
                              }
                            >
                              Funeral Director
                            </label>
                          </div>
                          {/* <!-- Family of Deceased --> */}
                          <div>
                            <input
                              type="radio"
                              id={
                                Names.funeralHomeShipMerchandiseToFamilyOfDeceased
                              }
                              name={Names.funeralHomeShipMerchandiseTo}
                              value={
                                Names.funeralHomeShipMerchandiseToFamilyOfDeceased
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="ml-2 text-sm"
                              htmlFor={
                                Names.funeralHomeShipMerchandiseToFamilyOfDeceased
                              }
                            >
                              Family of Deceased
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- separator --> */}
                    <hr className="mb-4 mt-4 mx-3" />

                    {/* <!-- deceased information --> */}
                    <div>
                      {/* <!-- Full Name of Decesead --> */}
                      <div className="flex">
                        <div className="w-full px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.deceasedName}
                          >
                            Full Name of Deceased
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.deceasedName}
                            id={Names.deceasedName}
                            value={values.deceasedName}
                            onChange={handleChange}
                            type="text"
                            placeholder=""
                          />
                        </div>
                      </div>
                      {/* <!-- Date of Birth, Date of Death --> */}
                      <div className="flex flex-wrap">
                        {/* <!-- Date of Birth --> */}
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.deceasedDateOfBirth}
                          >
                            Date of Birth
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.deceasedDateOfBirth}
                            id={Names.deceasedDateOfBirth}
                            value={values.deceasedDateOfBirth}
                            onChange={handleChange}
                            type="date"
                            placeholder=""
                          />
                        </div>
                        {/* <!-- Date of Death --> */}
                        <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.deceasedDateOfDeath}
                          >
                            Date of Death
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            name={Names.deceasedDateOfDeath}
                            id={Names.deceasedDateOfDeath}
                            value={values.deceasedDateOfDeath}
                            onChange={handleChange}
                            type="date"
                            placeholder=""
                          />
                        </div>
                      </div>
                      {/* <!-- Interment Information --> */}
                      <div className="flex">
                        <div className="w-full px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={Names.deceasedIntermentInformation}
                          >
                            Interment Information
                          </label>
                          <textarea
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id={Names.deceasedIntermentInformation}
                            name={Names.deceasedIntermentInformation}
                            value={values.deceasedIntermentInformation}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    {/* <!-- separator --> */}

                    <hr className="mb-4 mt-4 mx-3" />

                    {/* <!-- family contact --> */}

                    {values.funeralHomeShipMerchandiseTo ===
                      "FamilyOfDeceased" && (
                      <>
                        <div>
                          {/* <!-- Name of Family Contact --> */}
                          <div className="flex">
                            <div className="w-full px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactName}
                              >
                                Name of Family Contact
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactName}
                                id={Names.familyContactName}
                                value={values.familyContactName}
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {/* <!-- street --> */}
                          <div className="flex">
                            <div className="w-full px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactStreet}
                              >
                                Street
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactStreet}
                                id={Names.familyContactStreet}
                                value={values.familyContactStreet}
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {/* <!-- City, State, ZIP --> */}
                          <div className="flex flex-wrap">
                            {/* <!-- City --> */}
                            <div className="w-full md:w-1/3 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactCity}
                              >
                                City
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactCity}
                                id={Names.familyContactCity}
                                value={values.familyContactCity}
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                            {/* <!-- State --> */}
                            <div className="w-full px-3 md:w-1/3 relative">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactState}
                              >
                                State
                              </label>
                              <select
                                name={Names.familyContactState}
                                id={Names.familyContactState}
                                value={values.familyContactState}
                                onChange={handleChange}
                                className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              >
                                <option value="">Please select</option>
                                <option value="Alabama">Alabama</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Arizona">Arizona</option>
                                <option value="Arkansas">Arkansas</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Delaware">Delaware</option>
                                <option value="District Of Columbia">
                                  District Of Columbia
                                </option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Kentucky">Kentucky</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">
                                  Massachusetts
                                </option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Mississippi">Mississippi</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Hampshire">
                                  New Hampshire
                                </option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="North Carolina">
                                  North Carolina
                                </option>
                                <option value="North Dakota">
                                  North Dakota
                                </option>
                                <option value="Ohio">Ohio</option>
                                <option value="Oklahoma">Oklahoma</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">
                                  Pennsylvania
                                </option>
                                <option value="Rhode Island">
                                  Rhode Island
                                </option>
                                <option value="South Carolina">
                                  South Carolina
                                </option>
                                <option value="South Dakota">
                                  South Dakota
                                </option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Utah">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="Vermont">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">
                                  West Virginia
                                </option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>
                              </select>
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                                <svg
                                  className="fill-current h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                            {/* <!-- ZIP --> */}
                            <div className="w-full md:w-1/3 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactZip}
                              >
                                Zip Code
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactZip}
                                id={Names.familyContactZip}
                                value={values.familyContactZip}
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {/* <!-- Telephone, Email --> */}
                          <div className="flex flex-wrap">
                            {/* <!-- Telephone --> */}
                            <div className="w-full md:w-1/2 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactTelephone}
                              >
                                Telephone
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactTelephone}
                                id={Names.familyContactTelephone}
                                value={values.familyContactTelephone}
                                onChange={handleChange}
                                type="tel"
                                placeholder=""
                              />
                            </div>
                            {/* <!-- Email --> */}
                            <div className="w-full md:w-1/2 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={Names.familyContactEmail}
                              >
                                Email
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                name={Names.familyContactEmail}
                                id={Names.familyContactEmail}
                                value={values.familyContactEmail}
                                onChange={handleChange}
                                type="email"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <!-- Sympathy acknowledgements --> */}
                  <div>
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-2 text-left">
                      Sympathy Acknowledgement Cards
                    </h3>
                    {/* <!-- Quantity, sentiment --> */}
                    <div className="flex flex-wrap">
                      {/* <!-- quantity --> */}
                      <div
                        className={
                          values.sympathyAcknowledgementsQuantity === "0"
                            ? "w-full md:w-1/5 px-3 relative"
                            : "w-full px-3 md:w-1/3 relative"
                        }
                      >
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.sympathyAcknowledgementsQuantity}
                        >
                          Quantity
                        </label>
                        <select
                          name={Names.sympathyAcknowledgementsQuantity}
                          id={Names.sympathyAcknowledgementsQuantity}
                          value={values.sympathyAcknowledgementsQuantity}
                          onChange={handleChange}
                          className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Please select</option>
                          <option value="0">0</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                          <option value="125">125</option>
                          <option value="150">150</option>
                          <option value="175">175</option>
                          <option value="200">200</option>
                          <option value="225">225</option>
                          <option value="250">250</option>
                          <option value="275">275</option>
                          <option value="300">300</option>
                          <option value="325">325</option>
                          <option value="350">350</option>
                          <option value="375">375</option>
                          <option value="400">400</option>
                          <option value="425">425</option>
                          <option value="450">450</option>
                          <option value="475">475</option>
                          <option value="500">500</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>

                      {values.sympathyAcknowledgementsQuantity !== "0" && (
                        <>
                          {/* <!--Colors    Sentiment --> */}

                          <div className="w-full px-3 md:w-1/3 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsColors}
                            >
                              Colors
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsColors}
                              id={Names.sympathyAcknowledgementsColors}
                              value={values.sympathyAcknowledgementsColors}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>
                          <div className="w-full px-3 md:w-1/3 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsSentiment}
                            >
                              Sentiment
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsSentiment}
                              id={Names.sympathyAcknowledgementsSentiment}
                              value={values.sympathyAcknowledgementsSentiment}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="s-1">S-1</option>
                              <option value="s-2">S-2</option>
                              <option value="s-3">S-3</option>
                              <option value="s-4">S-4</option>
                              <option value="s-5">S-5</option>
                              <option value="s-6">S-6</option>
                              <option value="s-7">S-7</option>
                              <option value="s-8">S-8</option>
                              <option value="s-9">S-9</option>
                              <option value="s-10">S-10</option>
                              <option value="s-11">S-11</option>
                              <option value="s-12">S-12</option>
                            </select>
                          </div>
                        </>
                      )}
                    </div>

                    {values.sympathyAcknowledgementsQuantity !== "0" && (
                      <>
                        {/* <!-- Font, ink, motif, ink, paper --> */}
                        <div className="flex flex-wrap">
                          {/* <!-- Font --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsFont}
                            >
                              Font
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsFont}
                              id={Names.sympathyAcknowledgementsFont}
                              value={values.sympathyAcknowledgementsFont}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="F-1">F-1</option>
                              <option value="F-2">F-2</option>
                              <option value="F-3">F-3</option>
                              <option value="F-4">F-4</option>
                              <option value="F-5">F-5</option>
                              <option value="F-6">F-6</option>
                              <option value="F-7">F-7</option>
                              <option value="F-9">F-9</option>
                              <option value="F-10">F-10</option>
                              <option value="F-11">F-11</option>
                              <option value="F-12">F-12</option>
                              <option value="F-13">F-13</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Font ink --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsFontInk}
                            >
                              Font Ink
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsFontInk}
                              id={Names.sympathyAcknowledgementsFontInk}
                              value={values.sympathyAcknowledgementsFontInk}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="I-1 Black">I-1 Black</option>
                              <option value="I-2 Grey">I-2 Grey</option>
                              <option value="I-3 Green">I-3 Green</option>
                              <option value="I-4 Royal">I-4 Royal</option>
                              <option value="I-5 Brown">I-5 Brown</option>
                              <option value="I-6 Pink">I-6 Pink</option>
                              <option value="I-7 Gold">I-7 Gold</option>
                              <option value="I-8 Red">I-8 Red</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Motif --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsMotif}
                            >
                              Motif
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsMotif}
                              id={Names.sympathyAcknowledgementsMotif}
                              value={values.sympathyAcknowledgementsMotif}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="M-1">M-1</option>
                              <option value="M-2">M-2</option>
                              <option value="M-3">M-3</option>
                              <option value="M-4">M-4</option>
                              <option value="M-5">M-5</option>
                              <option value="M-6">M-6</option>
                              <option value="M-7">M-7</option>
                              <option value="M-8">M-8</option>
                              <option value="M-9">M-9</option>
                              <option value="M-10">M-10</option>
                              <option value="M-11">M-11</option>
                              <option value="M-12">M-12</option>
                              <option value="M-13">M-13</option>
                              <option value="M-14">M-14</option>
                              <option value="M-15">M-15</option>
                              <option value="M-16">M-16</option>
                              <option value="M-17">M-17</option>
                              <option value="M-18">M-18</option>
                              <option value="M-19">M-19</option>
                              <option value="M-20">M-20</option>
                              <option value="M-21">M-21</option>
                              <option value="M-22">M-22</option>
                              <option value="M-23">M-23</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Motif ink --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsMotifInk}
                            >
                              Motif Ink
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsMotifInk}
                              id={Names.sympathyAcknowledgementsMotifInk}
                              value={values.sympathyAcknowledgementsMotifInk}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="I-1 Black">I-1 Black</option>
                              <option value="I-2 Grey">I-2 Grey</option>
                              <option value="I-3 Green">I-3 Green</option>
                              <option value="I-4 Royal">I-4 Royal</option>
                              <option value="I-5 Brown">I-5 Brown</option>
                              <option value="I-6 Pink">I-6 Pink</option>
                              <option value="I-7 Gold">I-7 Gold</option>
                              <option value="I-8 Red">I-8 Red</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Paper --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.sympathyAcknowledgementsPaper}
                            >
                              Paper
                            </label>
                            <select
                              name={Names.sympathyAcknowledgementsPaper}
                              id={Names.sympathyAcknowledgementsPaper}
                              value={values.sympathyAcknowledgementsPaper}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="P-W">P-W White</option>
                              <option value="P-E">P-E Ecru</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {/* <!-- return address imprint --> */}
                        <div>
                          <label
                            className="mt-2 px-3 block  tracking-wide text-gray-500 text-sm  mb-2"
                            htmlFor="return-address-imprint"
                          >
                            Return Address Imprint
                          </label>
                          {/* <!-- street --> */}
                          <div className="flex">
                            <div className="w-full px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={
                                  Names.sympathyAcknowledgementsReturnAddressStreet
                                }
                              >
                                Street
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id={
                                  Names.sympathyAcknowledgementsReturnAddressStreet
                                }
                                name={
                                  Names.sympathyAcknowledgementsReturnAddressStreet
                                }
                                value={
                                  values.sympathyAcknowledgementsReturnAddressStreet
                                }
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          {/* <!-- City, State, ZIP --> */}
                          <div className="flex flex-wrap">
                            {/* <!-- City --> */}
                            <div className="w-full md:w-1/3 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={
                                  Names.sympathyAcknowledgementsReturnAddressCity
                                }
                              >
                                City
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id={
                                  Names.sympathyAcknowledgementsReturnAddressCity
                                }
                                name={
                                  Names.sympathyAcknowledgementsReturnAddressCity
                                }
                                value={
                                  values.sympathyAcknowledgementsReturnAddressCity
                                }
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                            {/* <!-- State --> */}
                            <div className="w-full px-3 md:w-1/3 relative">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={
                                  Names.sympathyAcknowledgementsReturnAddressState
                                }
                              >
                                State
                              </label>
                              <select
                                name={
                                  Names.sympathyAcknowledgementsReturnAddressState
                                }
                                id={
                                  Names.sympathyAcknowledgementsReturnAddressState
                                }
                                value={
                                  values.sympathyAcknowledgementsReturnAddressState
                                }
                                onChange={handleChange}
                                className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              >
                                <option value="">Please select</option>
                                <option value="Alabama">Alabama</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Arizona">Arizona</option>
                                <option value="Arkansas">Arkansas</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Delaware">Delaware</option>
                                <option value="District Of Columbia">
                                  District Of Columbia
                                </option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Kentucky">Kentucky</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">
                                  Massachusetts
                                </option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Mississippi">Mississippi</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Hampshire">
                                  New Hampshire
                                </option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="North Carolina">
                                  North Carolina
                                </option>
                                <option value="North Dakota">
                                  North Dakota
                                </option>
                                <option value="Ohio">Ohio</option>
                                <option value="Oklahoma">Oklahoma</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">
                                  Pennsylvania
                                </option>
                                <option value="Rhode Island">
                                  Rhode Island
                                </option>
                                <option value="South Carolina">
                                  South Carolina
                                </option>
                                <option value="South Dakota">
                                  South Dakota
                                </option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Utah">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="Vermont">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">
                                  West Virginia
                                </option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>
                              </select>
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                                <svg
                                  className="fill-current h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                            {/* <!-- ZIP --> */}
                            <div className="w-full md:w-1/3 px-3">
                              <label
                                className="block  tracking-wide text-gray-700 text-sm  mb-2"
                                htmlFor={
                                  Names.sympathyAcknowledgementsReturnAddressZip
                                }
                              >
                                Zip Code
                              </label>
                              <input
                                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id={
                                  Names.sympathyAcknowledgementsReturnAddressZip
                                }
                                name={
                                  Names.sympathyAcknowledgementsReturnAddressZip
                                }
                                value={
                                  values.sympathyAcknowledgementsReturnAddressZip
                                }
                                onChange={handleChange}
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <hr className="mb-4 mt-4 mx-3" />

                  {/* <!-- REMEMBRANCE PORTRAITS --> */}
                  <div>
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-2 text-left">
                      Remembrance Portraits
                    </h3>
                    {/* <!-- Style, Size --> */}
                    <div className="flex flex-wrap">
                      {/* <!-- Style --> */}
                      <div
                        className={
                          values.remembrancePotraitStyle === "None"
                            ? "w-full px-3 md:w-1/5 relative"
                            : "w-full px-3 md:w-1/3 relative"
                        }
                      >
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.remembrancePotraitStyle}
                        >
                          Style
                        </label>
                        <select
                          name={Names.remembrancePotraitStyle}
                          id={Names.remembrancePotraitStyle}
                          value={values.remembrancePotraitStyle}
                          onChange={handleChange}
                          className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Please select</option>
                          <option value="None">None</option>
                          <option value="style1">style1</option>
                          <option value="style2">style2</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {values.remembrancePotraitStyle !== "None" && (
                        <>
                          {/* <!-- Size --> */}
                          <div className="w-full px-3 md:w-1/3 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.remembrancePotraitSize}
                            >
                              Size
                            </label>
                            <select
                              name={Names.remembrancePotraitSize}
                              id={Names.remembrancePotraitSize}
                              value={values.remembrancePotraitSize}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="large-16x20">Large 16 x 20</option>
                              <option value="small-9x12">Small 9 x 12</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- upload photo --> */}
                          <div className="w-full px-3 md:w-1/3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.remembrancePotraitPhoto}
                            >
                              Photo
                            </label>
                            <input
                              className="appearance-none block w-full text-gray-700 py-2 leading-tight focus:outline-none focus:bg-white"
                              id={Names.remembrancePotraitPhoto}
                              name={Names.remembrancePotraitPhoto}
                              //value={values.remembrancePotraitPhoto}
                              onChange={(event) => {
                                setFieldValue(
                                  Names.remembrancePotraitPhoto,
                                  event.target.files
                                );
                              }}
                              type="file"
                              multiple
                            />
                          </div>
                        </>
                      )}
                    </div>

                    {/* <!-- Special Instructions --> */}
                    {values.remembrancePotraitStyle !== "None" && (
                      <div className="flex">
                        <div className="w-full px-3">
                          <label
                            className="block  tracking-wide text-gray-700 text-sm  mb-2"
                            htmlFor={
                              Names.remembrancePotraitSpecialInsctructions
                            }
                          >
                            Special Instructions
                          </label>
                          <textarea
                            className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id={Names.remembrancePotraitSpecialInsctructions}
                            name={Names.remembrancePotraitSpecialInsctructions}
                            value={
                              values.remembrancePotraitSpecialInsctructions
                            }
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                    )}
                  </div>

                  <hr className="mb-4 mt-4 mx-3" />
                  {/* <!-- memorial prayer cards --> */}
                  <div>
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-3 text-left">
                      Memorial Prayer Cards
                    </h3>
                    <div className="flex flex-wrap">
                      {/* <!-- quantity --> */}
                      <div
                        className={
                          values.memorialPrayerCardQuantity === "0"
                            ? "w-full px-3 md:w-1/5 relative"
                            : "w-full px-3 md:w-1/5 relative"
                        }
                      >
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.memorialPrayerCardQuantity}
                        >
                          Quantity
                        </label>
                        <select
                          name={Names.memorialPrayerCardQuantity}
                          id={Names.memorialPrayerCardQuantity}
                          value={values.memorialPrayerCardQuantity}
                          onChange={handleChange}
                          className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Please select</option>
                          <option value="0">0</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                          <option value="125">125</option>
                          <option value="150">150</option>
                          <option value="175">175</option>
                          <option value="200">200</option>
                          <option value="225">225</option>
                          <option value="250">250</option>
                          <option value="275">275</option>
                          <option value="300">300</option>
                          <option value="325">325</option>
                          <option value="350">350</option>
                          <option value="375">375</option>
                          <option value="400">400</option>
                          <option value="425">425</option>
                          <option value="450">450</option>
                          <option value="475">475</option>
                          <option value="500">500</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {values.memorialPrayerCardQuantity !== "0" && (
                        <>
                          {/* <!-- Style --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialPrayerCardStyle}
                            >
                              Style
                            </label>
                            <select
                              name={Names.memorialPrayerCardStyle}
                              id={Names.memorialPrayerCardStyle}
                              value={values.memorialPrayerCardStyle}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="style-1">Style 1</option>
                              <option value="style-2">Style 2</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Verse --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialPrayerCardVerse}
                            >
                              Verse
                            </label>
                            <select
                              name={Names.memorialPrayerCardVerse}
                              id={Names.memorialPrayerCardVerse}
                              value={values.memorialPrayerCardVerse}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="verse-1">Verse 1</option>
                              <option value="verse-2">Verse 2</option>
                              <option value="verse-3">Verse 3</option>
                              <option value="customVerse">Custom Verse</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- ink --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialPrayerCardInk}
                            >
                              Ink
                            </label>
                            <select
                              name={Names.memorialPrayerCardInk}
                              id={Names.memorialPrayerCardInk}
                              value={values.memorialPrayerCardInk}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="I-1 Black">I-1 Black</option>
                              <option value="I-2 Grey">I-2 Grey</option>
                              <option value="I-3 Green">I-3 Green</option>
                              <option value="I-4 Royal">I-4 Royal</option>
                              <option value="I-5 Brown">I-5 Brown</option>
                              <option value="I-6 Pink">I-6 Pink</option>
                              <option value="I-7 Gold">I-7 Gold</option>
                              <option value="I-8 Red">I-8 Red</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Paper --> */}
                          <div className="w-full px-3 md:w-1/5 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialPrayerCardPaper}
                            >
                              Paper
                            </label>
                            <select
                              name={Names.memorialPrayerCardPaper}
                              id={Names.memorialPrayerCardPaper}
                              value={values.memorialPrayerCardPaper}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="P-W">P-W White</option>
                              <option value="P-E">P-E Ecru</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {/* <!-- Interment Information and custom verse --> */}
                    {values.memorialPrayerCardQuantity !== "0" && (
                      <>
                        <div className="flex wrap">
                          {/* <!-- Custom Verse File--> */}
                          <div className="w-full px-3 md:w-1/2">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialPrayerCardCustomVerseFile}
                            >
                              Custom Verse
                            </label>
                            <input
                              className="appearance-none block w-full text-gray-700 py-2 leading-tight focus:outline-none focus:bg-white"
                              id={Names.memorialPrayerCardCustomVerseFile}
                              name={Names.memorialPrayerCardCustomVerseFile}
                              // value={values.memorialPrayerCardCustomVerseFile}
                              onChange={(event) => {
                                setFieldValue(
                                  Names.memorialPrayerCardCustomVerseFile,
                                  event.target.files
                                );
                              }}
                              type="file"
                              multiple
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <hr className="mb-4 mt-4 mx-3" />

                  {/* <!-- memorial Bookmark Obituary --> */}
                  <div>
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-3 text-left">
                      Memorial Bookmark Obituary
                    </h3>
                    <div className="flex flex-wrap">
                      {/* <!-- quantity --> */}
                      <div
                        className={
                          values.memorialBookmarkObituaryQuantity === "0"
                            ? "w-full px-3 md:w-1/5 relative"
                            : "w-full px-3 md:w-1/5 relative"
                        }
                      >
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.memorialBookmarkObituaryQuantity}
                        >
                          Quantity
                        </label>
                        <select
                          name={Names.memorialBookmarkObituaryQuantity}
                          id={Names.memorialBookmarkObituaryQuantity}
                          value={values.memorialBookmarkObituaryQuantity}
                          onChange={handleChange}
                          className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Please select</option>
                          <option value="0">0</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                          <option value="125">125</option>
                          <option value="150">150</option>
                          <option value="175">175</option>
                          <option value="200">200</option>
                          <option value="225">225</option>
                          <option value="250">250</option>
                          <option value="275">275</option>
                          <option value="300">300</option>
                          <option value="325">325</option>
                          <option value="350">350</option>
                          <option value="375">375</option>
                          <option value="400">400</option>
                          <option value="425">425</option>
                          <option value="450">450</option>
                          <option value="475">475</option>
                          <option value="500">500</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {values.memorialBookmarkObituaryQuantity !== "0" && (
                        <>
                          {/* <!-- Paper --> */}
                          <div className="w-full px-3 md:w-1/4 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialBookmarkObituaryPaper}
                            >
                              Paper
                            </label>
                            <select
                              name={Names.memorialBookmarkObituaryPaper}
                              id={Names.memorialBookmarkObituaryPaper}
                              value={values.memorialBookmarkObituaryPaper}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="P-W">P-W White</option>
                              <option value="P-E">P-E Ecru</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- Tassel Color --> */}
                          <div className="w-full px-3 md:w-1/4 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={
                                Names.memorialBookmarkObituaryTasselColor
                              }
                            >
                              Tassel Color
                            </label>
                            <select
                              name={Names.memorialBookmarkObituaryTasselColor}
                              id={Names.memorialBookmarkObituaryTasselColor}
                              value={values.memorialBookmarkObituaryTasselColor}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="black">Black</option>
                              <option value="white">White</option>
                              <option value="silver">Silver</option>
                              <option value="golf">Gold</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- upload obituary file --> */}
                          <div className="w-full px-3 md:w-1/4">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.memorialBookmarkObituaryFile}
                            >
                              Obituary File
                            </label>
                            <input
                              className="appearance-none block w-full text-gray-700 py-2 leading-tight focus:outline-none focus:bg-white"
                              name={Names.memorialBookmarkObituaryFile}
                              id={Names.memorialBookmarkObituaryFile}
                              //value={values.memorialBookmarkObituaryFile}
                              onChange={(event) => {
                                setFieldValue(
                                  Names.memorialBookmarkObituaryFile,
                                  event.target.files
                                );
                              }}
                              type="file"
                              multiple
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <hr className="mb-4 mt-4 mx-3" />

                  {/* <!-- Donation Cards With Envelopes --> */}
                  <div className="">
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-3 text-left">
                      Donation Cards with Envelopes
                    </h3>
                    {/* <!-- quantity --> */}
                    <div
                      className={
                        values.donationCardsWithEnvelopesQuantity === "0"
                          ? "w-full px-3 md:w-1/5 relative"
                          : "w-full px-3  relative"
                      }
                    >
                      <label
                        className="block  tracking-wide text-gray-700 text-sm  mb-2"
                        htmlFor={Names.donationCardsWithEnvelopesQuantity}
                      >
                        Quantity
                      </label>
                      <select
                        name={Names.donationCardsWithEnvelopesQuantity}
                        id={Names.donationCardsWithEnvelopesQuantity}
                        value={values.donationCardsWithEnvelopesQuantity}
                        onChange={handleChange}
                        className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="">Please select</option>
                        <option value="0">0</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="125">125</option>
                        <option value="150">150</option>
                        <option value="175">175</option>
                        <option value="200">200</option>
                        <option value="225">225</option>
                        <option value="250">250</option>
                        <option value="275">275</option>
                        <option value="300">300</option>
                        <option value="325">325</option>
                        <option value="350">350</option>
                        <option value="375">375</option>
                        <option value="400">400</option>
                        <option value="425">425</option>
                        <option value="450">450</option>
                        <option value="475">475</option>
                        <option value="500">500</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-6 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {values.donationCardsWithEnvelopesQuantity !== "0" && (
                      <>
                        {/* <!-- Donation Information --> */}
                        <div className="flex">
                          <div className="w-full px-3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={
                                Names.donationCardsWithEnvelopesDonationInformation
                              }
                            >
                              Donation Information
                            </label>
                            <textarea
                              className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id={
                                Names.donationCardsWithEnvelopesDonationInformation
                              }
                              name={
                                Names.donationCardsWithEnvelopesDonationInformation
                              }
                              value={
                                values.donationCardsWithEnvelopesDonationInformation
                              }
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                        {/* <!-- Recipient Address --> */}
                        <label
                          className="px-3 block  tracking-wide text-gray-500 text-sm  mb-2"
                          htmlFor="donation-cards-with-envelopes-recepient-address-street"
                        >
                          Recipient Address
                        </label>
                        {/* <!-- street --> */}
                        <div className="flex">
                          <div className="w-full px-3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={
                                Names.donationCardsWithEnvelopesRecepientAddressStreet
                              }
                            >
                              Street
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              name={
                                Names.donationCardsWithEnvelopesRecepientAddressStreet
                              }
                              id={
                                Names.donationCardsWithEnvelopesRecepientAddressStreet
                              }
                              value={
                                values.donationCardsWithEnvelopesRecepientAddressStreet
                              }
                              onChange={handleChange}
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>
                        {/* <!-- City, State, ZIP --> */}
                        <div className="flex flex-wrap">
                          {/* <!-- City --> */}
                          <div className="w-full md:w-1/3 px-3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={
                                Names.donationCardsWithEnvelopesRecepientAddressCity
                              }
                            >
                              City
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              name={
                                Names.donationCardsWithEnvelopesRecepientAddressCity
                              }
                              id={
                                Names.donationCardsWithEnvelopesRecepientAddressCity
                              }
                              value={
                                values.donationCardsWithEnvelopesRecepientAddressCity
                              }
                              onChange={handleChange}
                              type="text"
                              placeholder=""
                            />
                          </div>
                          {/* <!-- State --> */}
                          <div className="w-full px-3 md:w-1/3 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={
                                Names.donationCardsWithEnvelopesRecepientAddressState
                              }
                            >
                              State
                            </label>
                            <select
                              name={
                                Names.donationCardsWithEnvelopesRecepientAddressState
                              }
                              id={
                                Names.donationCardsWithEnvelopesRecepientAddressState
                              }
                              value={
                                values.donationCardsWithEnvelopesRecepientAddressState
                              }
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="Alabama">Alabama</option>
                              <option value="Alaska">Alaska</option>
                              <option value="Arizona">Arizona</option>
                              <option value="Arkansas">Arkansas</option>
                              <option value="California">California</option>
                              <option value="Colorado">Colorado</option>
                              <option value="Connecticut">Connecticut</option>
                              <option value="Delaware">Delaware</option>
                              <option value="District Of Columbia">
                                District Of Columbia
                              </option>
                              <option value="Florida">Florida</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Idaho">Idaho</option>
                              <option value="Illinois">Illinois</option>
                              <option value="Indiana">Indiana</option>
                              <option value="Iowa">Iowa</option>
                              <option value="Kansas">Kansas</option>
                              <option value="Kentucky">Kentucky</option>
                              <option value="Louisiana">Louisiana</option>
                              <option value="Maine">Maine</option>
                              <option value="Maryland">Maryland</option>
                              <option value="Massachusetts">
                                Massachusetts
                              </option>
                              <option value="Michigan">Michigan</option>
                              <option value="Minnesota">Minnesota</option>
                              <option value="Mississippi">Mississippi</option>
                              <option value="Missouri">Missouri</option>
                              <option value="Montana">Montana</option>
                              <option value="Nebraska">Nebraska</option>
                              <option value="Nevada">Nevada</option>
                              <option value="New Hampshire">
                                New Hampshire
                              </option>
                              <option value="New Jersey">New Jersey</option>
                              <option value="New Mexico">New Mexico</option>
                              <option value="New York">New York</option>
                              <option value="North Carolina">
                                North Carolina
                              </option>
                              <option value="North Dakota">North Dakota</option>
                              <option value="Ohio">Ohio</option>
                              <option value="Oklahoma">Oklahoma</option>
                              <option value="Oregon">Oregon</option>
                              <option value="Pennsylvania">Pennsylvania</option>
                              <option value="Rhode Island">Rhode Island</option>
                              <option value="South Carolina">
                                South Carolina
                              </option>
                              <option value="South Dakota">South Dakota</option>
                              <option value="Tennessee">Tennessee</option>
                              <option value="Texas">Texas</option>
                              <option value="Utah">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="Vermont">Virginia</option>
                              <option value="Washington">Washington</option>
                              <option value="West Virginia">
                                West Virginia
                              </option>
                              <option value="Wisconsin">Wisconsin</option>
                              <option value="Wyoming">Wyoming</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- ZIP --> */}
                          <div className="w-full md:w-1/3 px-3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm f mb-2"
                              htmlFor={
                                Names.donationCardsWithEnvelopesRecepientAddressZip
                              }
                            >
                              Zip Code
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              name={
                                Names.donationCardsWithEnvelopesRecepientAddressZip
                              }
                              id={
                                Names.donationCardsWithEnvelopesRecepientAddressZip
                              }
                              value={
                                values.donationCardsWithEnvelopesRecepientAddressZip
                              }
                              onChange={handleChange}
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <hr className="mb-4 mt-4 mx-3" />

                  {/* <!-- Funeral Programs --> */}
                  <div>
                    <h3 className="text-2xl sm:text-2xl md:text-2xl mt-6 mb-4 ml-3 text-left">
                      Funeral Service Programs
                    </h3>
                    <div className="flex flex-wrap">
                      {/* <!-- quantity --> */}
                      <div
                        className={
                          values.funeralProgramsQuantity === "0"
                            ? "w-full px-3 md:w-1/5 relative"
                            : "w-full px-3 md:w-1/3 relative"
                        }
                      >
                        <label
                          className="block  tracking-wide text-gray-700 text-sm  mb-2"
                          htmlFor={Names.funeralProgramsQuantity}
                        >
                          Quantity
                        </label>
                        <select
                          name={Names.funeralProgramsQuantity}
                          id={Names.funeralProgramsQuantity}
                          value={values.funeralProgramsQuantity}
                          onChange={handleChange}
                          className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Please select</option>
                          <option value="0">0</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                          <option value="125">125</option>
                          <option value="150">150</option>
                          <option value="175">175</option>
                          <option value="200">200</option>
                          <option value="225">225</option>
                          <option value="250">250</option>
                          <option value="275">275</option>
                          <option value="300">300</option>
                          <option value="325">325</option>
                          <option value="350">350</option>
                          <option value="375">375</option>
                          <option value="400">400</option>
                          <option value="425">425</option>
                          <option value="450">450</option>
                          <option value="475">475</option>
                          <option value="500">500</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {values.funeralProgramsQuantity !== "0" && (
                        <>
                          {/* <!-- Paper --> */}
                          <div className="w-full px-3 md:w-1/3 relative">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.funeralProgramsPaper}
                            >
                              Paper
                            </label>
                            <select
                              name={Names.funeralProgramsPaper}
                              id={Names.funeralProgramsPaper}
                              value={values.funeralProgramsPaper}
                              onChange={handleChange}
                              className="text-center appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            >
                              <option value="">Please select</option>
                              <option value="P-W">P-W White</option>
                              <option value="P-E">P-E Ecru</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-6 pt-4 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                          {/* <!-- upload photo --> */}
                          <div className="w-full px-3 md:w-1/3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.funeralProgramsUploadPhotoAndCopy}
                            >
                              Upload Photo and Text
                            </label>
                            <input
                              className="appearance-none block w-full text-gray-700 py-2 leading-tight focus:outline-none focus:bg-white"
                              id={Names.funeralProgramsUploadPhotoAndCopy}
                              name={Names.funeralProgramsUploadPhotoAndCopy}
                              //value={values.funeralProgramsUploadPhotoAndCopy}
                              onChange={(event) => {
                                setFieldValue(
                                  Names.funeralProgramsUploadPhotoAndCopy,
                                  event.target.files
                                );
                              }}
                              type="file"
                              multiple
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* <!-- Special Instructions --> */}
                    {values.funeralProgramsQuantity !== "0" && (
                      <>
                        <div className="flex">
                          <div className="w-full px-3">
                            <label
                              className="block  tracking-wide text-gray-700 text-sm  mb-2"
                              htmlFor={Names.funeralProgramsSpecialInstructions}
                            >
                              Special Instructions
                            </label>
                            <textarea
                              className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              id={Names.funeralProgramsSpecialInstructions}
                              name={Names.funeralProgramsSpecialInstructions}
                              value={values.funeralProgramsSpecialInstructions}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <ToastContainer></ToastContainer>
                  <hr className="mb-4 mt-4 mx-3" />
                  {errors.length > 0 &&
                    errors.map((err, index) => (
                      <div className="ml-2" style={{ color: "red" }}>
                        {`${index + 1} ${err}`}
                      </div>
                    ))}
                  {/* <!-- Submit button --> */}

                  <div className="mt-8">
                    <div className="text-right ">
                      {this.state.isSubmitting ? (
                        <div
                          style={{
                            marginTop: "20px",
                            marginBottom: "60px",
                            marginRight: "50px",
                            marginLeft: "51px",
                            position: "relative",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{ width: "1.5rem", height: "1.5rem" }}
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <button
                          className="btn btn-primary btn-lg transition-colors duration-300 ease-in-out mx-3   border-1 text-white hover:text-white  py-2 px-4 rounded"
                          type="submit"
                          name="submit"
                          value="submit"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </>

              {/* <!-- separator --> */}
              {/* <hr className="mb-4 mt-4 mx-3" /> */}
              {/* <!-- Footer --> */}
              <footer className="mb-8 text-center">
                <small className="text-sm text-gray-700">
                  2020 &copy; Crosstown Press, Inc.
                </small>
              </footer>
            </div>
          )}
        </Formik>
      </>
    );
  }
}

export default withRouter(Form);
