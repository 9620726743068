import React from "react";
import { withRouter } from "react-router-dom";

const Success = () => {
  localStorage.removeItem("data");
  return (
    <>
      <section className="p-5 text-2xl text-center font-semibold">
        <h3>Payment Cancelled</h3>
      </section>
    </>
  );
};

export default withRouter(Success);
