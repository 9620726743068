export const Fields = {
  funeralHomeName: "",
  funeralHomeContact: "",
  funeralHomeStreet: "",
  funeralHomeCity: "",
  funeralHomeState: "",
  funeralHomeZip: "",
  funeralHomeTelephone: "",
  funeralHomeEmail: "",
  funeralHomeShipMerchandiseTo: "",

  deceasedName: "",
  deceasedDateOfBirth: "",
  deceasedDateOfDeath: "",
  deceasedIntermentInformation: "",

  familyContactName: "",
  familyContactStreet: "",
  familyContactCity: "",
  familyContactState: "",
  familyContactZip: "",
  familyContactTelephone: "",
  familyContactEmail: "",

  sympathyAcknowledgementsQuantity: "0",
  sympathyAcknowledgementsColors: "1",
  sympathyAcknowledgementsSentiment: "",
  sympathyAcknowledgementsFont: "",
  sympathyAcknowledgementsFontInk: "",
  sympathyAcknowledgementsMotif: "",
  sympathyAcknowledgementsMotifInk: "",
  sympathyAcknowledgementsPaper: "",
  sympathyAcknowledgementsReturnAddressStreet: "",
  sympathyAcknowledgementsReturnAddressCity: "",
  sympathyAcknowledgementsReturnAddressState: "",
  sympathyAcknowledgementsReturnAddressZip: "",

  remembrancePotraitStyle: "None",
  remembrancePotraitSize: "",
  remembrancePotraitPhoto: "",
  remembrancePotraitSpecialInsctructions: "",

  memorialPrayerCardQuantity: "0",
  memorialPrayerCardStyle: "",
  memorialPrayerCardVerse: "",
  memorialPrayerCardInk: "",
  memorialPrayerCardPaper: "",
  memorialPrayerCardCustomVerseFile: "",

  memorialBookmarkObituaryQuantity: "0",
  memorialBookmarkObituaryPaper: "",
  memorialBookmarkObituaryTasselColor: "",
  memorialBookmarkObituaryFile: "",

  donationCardsWithEnvelopesQuantity: "0",
  donationCardsWithEnvelopesDonationInformation: "",
  donationCardsWithEnvelopesRecepientAddressStreet: "",
  donationCardsWithEnvelopesRecepientAddressCity: "",
  donationCardsWithEnvelopesRecepientAddressState: "",
  donationCardsWithEnvelopesRecepientAddressZip: "",

  funeralProgramsQuantity: "0",
  funeralProgramsPaper: "",
  funeralProgramsUploadPhotoAndCopy: "",
  funeralProgramsSpecialInstructions: "",
};
