import React, { useEffect } from "react";
import Form from "./From/Form";
import Success from "./pages/success";
import Failure from "./pages/failure";
import Home from './pages/home'
//import Axios from "axios";

import Checkout from "./stripe/strp";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";

const Routes = (props) => {
  useEffect(() => {
    ReactGA.initialize("G-0NM6P7XKGH");
    ReactGA.pageview("/");
  }, []);

  const domain = window.location.hostname ==="crosstown.press";
  return (
    <>
      <Switch>
        <Router>
           <Route
          exact
          path="/"
          component={() => {
            return  domain? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
          <Route exact path="/success">
            <div className="main-body">
              <Success />
            </div>
          </Route>
          <Route exact path="/failure">
            <div className="main-body">
              <Failure />
            </div>
          </Route>
          <Route exact path="/home">
            <div className="main-body">
              <Home />
            </div>
          </Route>

          <Route exact path="/">
            <Form />
          </Route>
          <Route exact path="/checkout">
            <div className="stripe-container main-body">
              <Checkout />
            </div>
          </Route>
        </Router>
      </Switch>
    </>
  );
};

export default Routes;
