import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./strp.css";
import Axios from "axios";
import { withRouter } from "react-router-dom";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProductDisplay = ({ handleClick }) => {
  const data = JSON.parse(localStorage.getItem("data"));
  const items = data.priceArr;
  const total = data.total;
  let transformedArr = [];
  for (let i = 0; i < items.length; i++) {
    const obj = {};
    for (const [key, value] of Object.entries(items[i])) {
      for (const [innerKey, innerValue] of Object.entries(value)) {
        obj[innerKey] = innerValue;
      }
      switch (key) {
        case "acknowledgementCard":
          obj.name = "Acknowledgement Cards";
          break;
        case "portrait":
          obj.name = "Portrait";
          break;
        case "prayerCard":
          obj.name = "Prayer CardS";
          break;
        case "bookmarkObituary":
          obj.name = "Bookmark Obituary";
          break;
        case "donationCards":
          obj.name = "Donation Cards";
          break;
        case "funeralProgram":
          obj.name = "FuneralProgram";
          break;

        default:
          break;
      }

      transformedArr.push(obj);
    }
  }

  return (
    <div className="p-3 border border grey-700 rounded m-5 p-5 mt-10">
      <h2 className="p-2 text-5xl text-center font-semibold border-bottom border-dark">
        Crosstown Press, Inc
      </h2>
      <h4 className="p-2 text-1xl text-center mb-2">Order Details</h4>
      <hr />
      {transformedArr.map((item) => (
        <>
          <div className="row">
            <div className="col block text-left  tracking-wide text-black text-base  mb-2">
              {item.name}:
            </div>
            <div className="col block  tracking-wide text-black text-base  mb-2">
              {item.amount}x1
            </div>
            <div className="col block  tracking-wide text-black text-base  mb-2">
              ${item.price}
            </div>
          </div>
        </>
      ))}
      <hr />
      <h4 className="p-2 text-1xl text-left mb-2">Grand Total : ${total}</h4>

      <button
        onClick={handleClick}
        className="btn btn-primary  p-2 btn-block btn-lg text-2xl text-center font-semibold"
      >
        Checkout
      </button>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function App(props) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleClick = async (event) => {
    const stripe = await stripePromise;
    const data = JSON.parse(localStorage.getItem("data"));
    const tok = data.token;
    props.history.push("/success");
    // const response = await Axios.post(
    //   `${process.env.REACT_APP_API_BASE_URL}/create-session`,
    //   { token: tok }
    // );

    // const session = null;
    //const session = await response.data;
    //localStorage.removeItem("token");
    // When the customer clicks on the button, redirect them to Checkout.
    // const result = await stripe.redirectToCheckout({
    //   sessionId: session.id,
    // });
    // if (result.error) {
    //   // If `redirectToCheckout` fails due to a browser or network
    //   // error, display the localized error message to your customer
    //   // using `result.error.message`.
    // }
  };

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay handleClick={handleClick} />
  );
}

export default withRouter(App);
