import Axios from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

class Success extends Component {
  state = {};
  componentDidMount = async () => {
    const data = JSON.parse(localStorage.getItem("data"));
    const tok = data.token;
    const response = await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/confirm-payment`,
      { token: tok }
    );
    console.log(response.data);
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <section className="mt-10 p-5 text-2xl text-center font-semibold align-items-center justify-content-center">
          <h1>Crosstown Press, Inc.</h1>
        </section>
      </>
    );
  }
}

export default withRouter(Success);
